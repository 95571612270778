import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: { name: 'About' }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/science',
    name: 'Science',
    component: () => import(/* webpackChunkName: "science" */ '../views/Science.vue')
  }, {
    path: '/fund_management',
    name: 'FundManagement',
    component: () => import(/* webpackChunkName: "fund_management" */ '../views/FundManagement.vue')
  }, {
    path: '/incubation',
    name: 'Incubation',
    component: () => import(/* webpackChunkName: "fund_management" */ '../views/Incubation.vue')
  },
  {
    path: '/team',
    name: 'Team',
    component: () => import(/* webpackChunkName: "team" */ '../views/Team.vue')
  },
  {
    path: '/events',
    name: 'Events',
    props: () => ({ type: 'upcoming' }),
    component: () => import(/* webpackChunkName: "events" */ '../views/Event.vue')
  },
  {
    path: '/news',
    name: 'News',
    props: () => ({ type: 'news' }),
    component: () => import(/* webpackChunkName: "news" */ '../views/Event.vue')
  },
  {
    path: '/insights',
    name: 'Insights',
    props: () => ({ type: 'insights' }),
    component: () => import(/* webpackChunkName: "insights" */ '../views/Event.vue')
  },
  {
    path: '/event/detail/:id',
    name: 'Event Detail',
    props: true,
    component: () => import(/* webpackChunkName: "event_detail" */ '../views/EventDetail.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
  }
]

const router = new VueRouter({
  scrollBehavior () {
    return {
      x: 0,
      y: 0
    }
  },
  routes
})

export default router
