<template>
  <!--  fixed="top"-->
  <b-navbar
    toggleable="lg"
    sticky
    class="custom"
  >
    <div class="container">
      <b-navbar-brand href="/">
        <img
          class="logo"
          src="@/assets/images/logo.png"
          alt=""
        >
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse" />
      <b-collapse
        id="nav-collapse"
        is-nav
      >
        <b-navbar-nav class="ml-auto menus">
          <b-nav-item to="/about">
            About Us
          </b-nav-item>
          <b-nav-item to="/science">
            Life Sciences
          </b-nav-item>
          <b-nav-item to="/fund_management">
            YAFO Fund
          </b-nav-item>
          <b-nav-item to="/incubation">
            Incubation
          </b-nav-item>
          <b-nav-item to="/team">
            Team
          </b-nav-item>
          <b-nav-item to="/events">
            Event
          </b-nav-item>
          <!--          <b-nav-item-dropdown text="Event">-->
          <!--            <b-dropdown-item to="/event/upcoming">-->
          <!--              Upcoming-->
          <!--            </b-dropdown-item>-->
          <!--            <b-dropdown-item to="/event/news">-->
          <!--              News-->
          <!--            </b-dropdown-item>-->
          <!--            <b-dropdown-item to="/event/weekly">-->
          <!--              Life Science Weekly-->
          <!--            </b-dropdown-item>-->
          <!--          </b-nav-item-dropdown>-->
          <b-nav-item to="/contact">
            Contact Us
          </b-nav-item>
          <!--          <b-nav-item-dropdown-->
          <!--            id="my-nav-dropdown"-->
          <!--            :text="$i18n.locale | getLang"-->
          <!--            toggle-class="nav-link-custom"-->
          <!--            right-->
          <!--          >-->
          <!--            <b-dropdown-item @click="changeLocale('zh-CN')">-->
          <!--              中文-->
          <!--            </b-dropdown-item>-->
          <!--            <b-dropdown-item @click="changeLocale('en')">-->
          <!--              English-->
          <!--            </b-dropdown-item>-->
          <!--          </b-nav-item-dropdown>-->
        </b-navbar-nav>
      </b-collapse>
    </div>
  </b-navbar>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'

export default @Component({
  filters: {
    getLang (lang) {
      switch (lang) {
        case 'zh-CN':
          return '中文'
        case 'en':
          return 'English'
        default:
          return lang
      }
    }
  }
})
class Navbar extends Vue {
  changeLocale (locale) {
    this.$i18n.locale = locale
    localStorage.setItem('locale', locale)
    localStorage.setItem('fallbackLocale', locale)
  }
}
</script>

<style lang="scss" scoped>
.custom {
  background: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  .menus {
    text-transform: uppercase;
  }

  .logo{
    height: 50px;
  }

  ::v-deep {
    .nav-link {
      font-size: 0.875rem;

      &:focus, &:hover {
        color: #e49100 !important;
      }

      &.router-link-active {
        color: #e49100;
      }
    }

    .navbar-toggler {
      border: none;
    }

    .dropdown-item {
      @extend .nav-link;
    }

  }
}
</style>
