import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function getDefaultLocale () {
  let result = []
  if (navigator.languages) {
    result = navigator.languages.filter(lang => ['zh-CN', 'en'].includes(lang))
  }
  return result.length ? result[0] : process.env.VUE_APP_I18N_LOCALE
}

function loadLocaleMessages () {
  const locales = require.context('./locales', true,
    /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

export default new VueI18n({
  locale: localStorage.getItem('locale') || getDefaultLocale(),
  fallbackLocale: localStorage.getItem('fallbackLocale') || getDefaultLocale(),
  messages: loadLocaleMessages()
})
