<template>
  <div>
    <footer class="container-fluid">
      <div class="container">
        <b-row>
          <b-col
            cols="12"
            sm="6"
          >
            <h3>Contact us</h3>
            <p style="word-break:break-all;">
              Address：2F,1305 South Suzhou Road,Shanghai
            </p>
            <p>
              Postcode：200003
            </p>
            <p>
              Email：<a
                target="_blank"
                href="mailto:BD@yafocapital.com"
              >BD@yafocapital.com</a>
            </p>
          </b-col>
          <b-col
            cols="12"
            sm="6"
          >
            <h3>联系我们</h3>
            <p>
              地址：上海市南苏州路1305号2楼
            </p>
            <p>
              邮编：200003
            </p>
            <p>
              邮箱：<a
                target="_blank"
                href="mailto:BD@yafocapital.com"
              >BD@yafocapital.com</a>
            </p>
          </b-col>
        </b-row>
      </div>
      <div
        class="text-center copyright"
      >
        All rights reserved @ Yafo Capital {{ currentYear }} &nbsp;&nbsp; <a
          style="color:#fff;"
          href="https://beian.miit.gov.cn/"
          target="_blank"
        >沪ICP备17012275号-2</a>
      </div>
    </footer>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import moment from 'moment'

export default @Component({})
class Foot extends Vue {
  currentYear = moment().get('year')
}
</script>

<style lang="scss" scoped>
footer {
  background: #1e1e1e;
  padding-top: 45px;
  padding-bottom: 50px;
  font-size: 14px;

  h3 {
    color: #fff;
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 1rem;
  }

  p {
    color: #fff;
    text-align: justify;
    cursor: pointer;

    a {
      color: #fff;
      text-decoration: none;

      &:hover {
        color: #e49100;
        text-decoration: none;
      }
    }
  }

  .copyright {
    color: #fff;
    margin-top: 10px;
    font-size: 12px;
  }
}

@media (max-width: 479px) {
  footer {
    padding-top: 20px;
    padding-bottom: 20px;

    h3 {
      font-size: 1.25rem;
      margin-top: 20px;
      margin-bottom: 1rem;
    }

    p {
      font-size: 12px;
      margin-bottom: 0.5rem;
    }
  }
}
</style>
