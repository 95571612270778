<template>
  <div
    id="app"
    class="app"
  >
    <navbar />
    <router-view
      :key="$route.path"
      class="container-fluid main-container"
    />
    <foot />
    <a
      v-if="showScrollTop"
      v-scroll-to="'#app'"
      class="scroll-top"
      href="#"
    >
      <img
        src="@/assets/images/to_top.png"
        alt=""
      >
    </a>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator'
import Navbar from '@/components/Navbar'
import Foot from '@/components/Foot'

export default @Component({
  components: {
    Navbar,
    Foot
  }
})
class App extends Vue {
  showScrollTop = false

  created () {
    window.addEventListener('scroll', this.handleScroll)
  }

  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll () {
    this.showScrollTop = window.scrollY > 0
  }
}
</script>

<style lang="scss" scoped>
#app {
  //padding-top: 90px;

  .main-container {
    padding-bottom: 60px;
  }

  .scroll-top {
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    z-index: 9999;
  }
}
</style>
